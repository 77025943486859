/* @import url('https://use.fontawesome.com/releases/v5.8.2/css/all.css'); */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
body{
	margin: 0;
	padding: 0;
	background: #f6f8f9;
	color: #5b5b5b;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 1px;
	font-size: 13px;
}
a{
	text-decoration: none;
}
ul, li{
	padding: 0;
	margin: 0;
	list-style: none;	
}
h1, h2, h3, h4, h5, h6{
	padding: 0;
	margin: 0;
	/* color: #3b5998; */
}
.hasSidebar{
    width: 100%;
    transition: all 0.25s;
    margin-left: 0px;
	overflow: hidden;
	min-height: 100vh;
}
.sidebarDisplayed{
	margin-left: 200px;
}
#form-dialog-title > h2{
	font-size: 20px;
	font-weight: 400;
}
@media only all and (min-width: 600px) {
	.hasSidebar{
		width: calc(100% - 200px);
		margin-left: 200px;
	}
}