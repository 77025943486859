@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);


@-webkit-keyframes moveup {
    0%   {opacity: 0; transform: translateY(12px);}
    100%  {opacity: 1; transform: translateY(0px);}
}

@keyframes moveup {
    0%   {opacity: 0; transform: translateY(12px);}
    100%  {opacity: 1; transform: translateY(0px);}
}

@-webkit-keyframes movedown {
    0%   {opacity: 1; transform: translateY(0px);}
    100%  {opacity: 0; transform: translateY(-12px);}
}

@keyframes movedown {
    0%   {opacity: 1; transform: translateY(0px);}
    100%  {opacity: 0; transform: translateY(-12px);}
}

.odometerStart{
    display: inline-block;
    -webkit-animation-name: modeup 0.25s;
            animation-name: modeup 0.25s;
    -webkit-animation: moveup 0.25s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.odometerEnd{
    display: inline-block;
    -webkit-animation-name: movedown 0.25s;
            animation-name: movedown 0.25s;
    -webkit-animation: movedown 0.25s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.odometer-block{
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.odometer-block{
	display: flex;
	justify-content: space-between;
}
.odometer-block > span{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 10%;
	background: #205e86;
	color: #FFF;
	padding: 1.5em;
	border-radius: 10px;
	font-size: 13px;
	text-transform: uppercase;
}
.odometer-block > span > span{
	padding: .5em 0em;
}

/* @import url('https://use.fontawesome.com/releases/v5.8.2/css/all.css'); */
body{
	margin: 0;
	padding: 0;
	background: #f6f8f9;
	color: #5b5b5b;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 1px;
	font-size: 13px;
}
a{
	text-decoration: none;
}
ul, li{
	padding: 0;
	margin: 0;
	list-style: none;	
}
h1, h2, h3, h4, h5, h6{
	padding: 0;
	margin: 0;
	/* color: #3b5998; */
}
.hasSidebar{
    width: 100%;
    transition: all 0.25s;
    margin-left: 0px;
	overflow: hidden;
	min-height: 100vh;
}
.sidebarDisplayed{
	margin-left: 200px;
}
#form-dialog-title > h2{
	font-size: 20px;
	font-weight: 400;
}
@media only all and (min-width: 600px) {
	.hasSidebar{
		width: calc(100% - 200px);
		margin-left: 200px;
	}
}
